export const DriverStatusTypes = {
    ready: 'Ready',
    requested: 'Requested',
    declined: 'Declined',
    dispatched: 'Dispatched',
    delivered: 'Delivered',
    awaitingRateVerification: 'AwaitingRateVerification',
    readyForInvoicing: 'ReadyForInvoicing',
    invoiced: 'Invoiced',
    breakdown: 'Breakdown',
    awaitingConfirmation: 'AwaitingConfirmation',
};
